// 文化 - 公告
import { CULTURE } from '../api'
import { METHOD, request } from '@/utils/request'

// 查询公告列表
export async function getNoticeList(params) {
  return request(`${CULTURE}/notice/getNoticeList`, METHOD.GET, params)
}

// 查询公告详情
export async function getNoticeDetail(params) {
  return request(`${CULTURE}/notice/getNoticeDetail`, METHOD.GET, params)
}

// 添加公告
export async function addNotice(params) {
  return request(`${CULTURE}/notice/addNotice`, METHOD.POST, params)
}

// 删除公告
export async function delNotice(params) {
  return request(`${CULTURE}/notice/delNotice`, METHOD.POST, params)
}

